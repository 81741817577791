import {
    Autosuggest,
    AutosuggestProps,
    ColumnLayout,
    Container,
    FormField,
    Header,
    Select,
    SelectProps,
    SpaceBetween,
    TokenGroup,
} from '@amzn/awsui-components-react-v3';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { LocationItemData } from '../../../../../common/interfaces/businessDataItem/locationItem';
import { InstructorProfileData } from '../../../../../common/interfaces/instructorProfile';
import {
    getSelectedOptionValue,
    valuesToOptions,
} from '../../../../../common/utils/cloudscapeHelpers';
import instructorManagementApi from '../../../../../imt/api/instructorManagementApi';
import { getCityString } from '../../../../../imt/components/Instructor/FormSections/BasicInfoFormSection';
import {
    activityGroupSlice,
    addDeliveryOptionWithUser,
} from '../../../../store/slices/activityGroupSlice';
import { useActivityGroup, useDeliveryDetailsFormOptions } from '../hooks';
import { AciMessage, AciResouceConstrain } from '../validations';

const DEFAULT_DELIVERY_NAME = 'Live Learning';
const MAX_INSTRUCTOR_SEARCH_RESULTS =
    AciResouceConstrain.MAX_INSTRUCTOR_SEARCH_RESULTS;
const MAX_INSTRUCTOR_POOL_SIZE =
    AciResouceConstrain.MAX_LAG_INSTRUCTOR_POOL_SIZE;

const DeliveryDetails = ({ isReadOnlyMode }: { isReadOnlyMode: boolean }) => {
    const dispatch = useDispatch();
    const activityGroup = useActivityGroup();
    const { activityTypes, activityAudiences, activityModalities, locations } =
        useDeliveryDetailsFormOptions();
    const [instructorOptions, setInstructorOptions] =
        useState<AutosuggestProps.Options>([]);
    const [instructorSearchText, setInstructorSearchText] =
        useState<string>('');
    const [isSearchingInstructors, setIsSearchingInstructors] =
        useState<boolean>(false);

    useEffect(() => {
        if (activityGroup.child_activities.length === 0) {
            dispatch(
                activityGroupSlice.actions.addDeliveryName(
                    DEFAULT_DELIVERY_NAME,
                ),
            );
            dispatch(addDeliveryOptionWithUser(DEFAULT_DELIVERY_NAME));
        }
    }, [activityGroup.child_activities[0]]);

    const child = activityGroup.child_activities[0];

    const locationOptions = locations.map<SelectProps.Option>((location) => ({
        value: JSON.stringify(location),
        label: getCityString(location),
    }));

    return (
        <Container header={<Header>Delivery Details</Header>}>
            <SpaceBetween size="l">
                <ColumnLayout columns={3}>
                    <FormField label="Activity Type">
                        <Select
                            options={valuesToOptions(activityTypes)}
                            selectedOption={
                                child
                                    ? getSelectedOptionValue(
                                          child.activity_type,
                                      )
                                    : null
                            }
                            placeholder="Select Activity Type"
                            disabled={isReadOnlyMode}
                            onChange={(e) =>
                                dispatch(
                                    activityGroupSlice.actions.setActivityType(
                                        e.detail.selectedOption.value,
                                    ),
                                )
                            }
                            data-testid="activity-type-select"
                        ></Select>
                    </FormField>

                    <FormField label="Audience">
                        <Select
                            options={valuesToOptions(activityAudiences)}
                            selectedOption={
                                child
                                    ? getSelectedOptionValue(
                                          child.activity_audience,
                                      )
                                    : null
                            }
                            placeholder="Select Audience"
                            disabled={isReadOnlyMode}
                            onChange={(e) =>
                                dispatch(
                                    activityGroupSlice.actions.setActivityAudience(
                                        e.detail.selectedOption.value,
                                    ),
                                )
                            }
                            data-testid="activity-audience-select"
                        ></Select>
                    </FormField>

                    <FormField label="Delivery Modality">
                        <Select
                            options={valuesToOptions(activityModalities)}
                            selectedOption={
                                child
                                    ? getSelectedOptionValue(
                                          child.activity_modality,
                                      )
                                    : null
                            }
                            placeholder="Select a Delivery Modality"
                            disabled={isReadOnlyMode}
                            onChange={(e) =>
                                dispatch(
                                    activityGroupSlice.actions.setActivityModality(
                                        e.detail.selectedOption.value,
                                    ),
                                )
                            }
                            data-testid="delivery-modality-select"
                        ></Select>
                    </FormField>

                    <FormField label="Delivery Location">
                        <Select
                            options={locationOptions}
                            selectedOption={
                                child
                                    ? getSelectedOptionValue(
                                          getCityString({
                                              state: child.delivery_state,
                                              city: child.delivery_city,
                                              state_province:
                                                  child.delivery_state,
                                              country: child.delivery_country,
                                          }),
                                      )
                                    : null
                            }
                            placeholder="Select a Delivery Location"
                            disabled={isReadOnlyMode}
                            onChange={(e) => {
                                const option = JSON.parse(
                                    e.detail.selectedOption.value,
                                ) as LocationItemData;
                                dispatch(
                                    activityGroupSlice.actions.setActivityLocation(
                                        {
                                            timezone: option.city_timezone,
                                            country: option.country,
                                            state: option.state,
                                            city: option.city,
                                            region: option.region,
                                            geo: option.geo,
                                        },
                                    ),
                                );
                            }}
                            filteringType="auto"
                            data-testid="delivery-location-select"
                        ></Select>
                    </FormField>
                </ColumnLayout>

                <FormField
                    stretch={true}
                    label="Instructor Pool"
                    constraintText={
                        activityGroup.instructor_pool.length >=
                            MAX_INSTRUCTOR_POOL_SIZE &&
                        AciMessage.MAX_LAG_INSTRUCTOR_POOL_SIZE
                    }
                >
                    <SpaceBetween direction="horizontal" size="s">
                        <Autosuggest
                            value={instructorSearchText}
                            placeholder="Search for an instructor"
                            loadingText="Searching..."
                            empty="No instructors found"
                            recoveryText="Retry search"
                            finishedText={
                                instructorOptions.length
                                    ? `Results for: ${instructorSearchText}`
                                    : 'No results'
                            }
                            statusType={
                                isSearchingInstructors ? 'loading' : 'finished'
                            }
                            filteringType="manual"
                            disabled={
                                isReadOnlyMode ||
                                activityGroup.instructor_pool.length >=
                                    MAX_INSTRUCTOR_POOL_SIZE
                            }
                            onChange={(e) => {
                                const clickedOption = instructorOptions.find(
                                    (instructor) =>
                                        instructor.value === e.detail.value,
                                );

                                if (clickedOption) {
                                    const instructor = JSON.parse(
                                        clickedOption.value,
                                    ) as InstructorProfileData;
                                    dispatch(
                                        activityGroupSlice.actions.addInstructorToPool(
                                            instructor,
                                        ),
                                    );
                                    setInstructorSearchText('');
                                    setInstructorOptions([]);
                                } else {
                                    setInstructorSearchText(e.detail.value);
                                }
                            }}
                            onLoadItems={async (e) => {
                                if (e.detail.filteringText) {
                                    setIsSearchingInstructors(true);
                                    setInstructorOptions([]);

                                    const { result } =
                                        await instructorManagementApi.getInstructors(
                                            {
                                                search_text:
                                                    e.detail.filteringText,
                                                from: 0,
                                                size: MAX_INSTRUCTOR_SEARCH_RESULTS,
                                            },
                                        );

                                    setInstructorOptions(
                                        result.instructors.map(
                                            (instructor) => ({
                                                value: JSON.stringify(
                                                    instructor,
                                                ),
                                                label: `${instructor.full_name} (${instructor.email})`,
                                            }),
                                        ),
                                    );

                                    setIsSearchingInstructors(false);
                                }
                            }}
                            options={instructorOptions}
                            data-testid="instructor-search"
                        ></Autosuggest>

                        <TokenGroup
                            disableOuterPadding
                            items={activityGroup.instructor_pool.map(
                                (instructor) => ({
                                    value: JSON.stringify(instructor),
                                    label: `${instructor.name} (${instructor.email})`,
                                }),
                            )}
                            readOnly={isReadOnlyMode}
                            onDismiss={({ detail: { itemIndex } }) => {
                                const instructor =
                                    activityGroup.instructor_pool[itemIndex];
                                dispatch(
                                    activityGroupSlice.actions.removeInstructorFromPool(
                                        instructor,
                                    ),
                                );
                            }}
                            data-testid="instructor-token-group"
                        ></TokenGroup>
                    </SpaceBetween>
                </FormField>
            </SpaceBetween>
        </Container>
    );
};

export default DeliveryDetails;
