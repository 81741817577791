import React from 'react';
import { StatusIndicator } from '@amzn/awsui-components-react-v3';
import { classroomStatusAlertMap } from './ClassroomsHelper';
import { EMPTY_STRING } from '../../../../../common/constants/grimsby';

const renderClassroomStatusMessage = (status: string) => {
    if (!status || !classroomStatusAlertMap[status]) {
        return EMPTY_STRING;
    }

    return (
        <>
            <StatusIndicator type={classroomStatusAlertMap[status].type}>
                {classroomStatusAlertMap[status].message}
            </StatusIndicator>
        </>
    );
};

export default renderClassroomStatusMessage;
