import { EMPTY_STRING } from '../../../../../common/constants/grimsby';
import { ClassroomStatus } from '../../../../interfaces/activity';

export const classroomStatusAlertMap = {
    [ClassroomStatus.CLASSROOM_READY]: {
        type: 'success',
        message: 'Ready',
    },
    [ClassroomStatus.CANCELLED]: {
        type: 'stopped',
        message: 'Cancelled',
    },
    [ClassroomStatus.READY_FOR_CLASSROOM_CREATION]: {
        type: 'in-progress',
        message: `In progress`,
    },
    [ClassroomStatus.ELIGIBLE_FOR_CLASSROOM_CREATION]: {
        type: null,
        message: EMPTY_STRING,
    },
    [ClassroomStatus.CLASSROOM_UPDATE_FAILED]: {
        type: 'error',
        message: 'Failed',
    },
    [ClassroomStatus.CLASSROOM_PROVISIONING_FAILED]: {
        type: 'error',
        message: 'Failed',
    },
};
