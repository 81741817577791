import React, { useCallback, useEffect } from 'react';
import AdminRoutes from './AdminRoutes';
import { useGrimsbyNavActions } from '../../../common/context/grimsbyNavigation';
import { ADMIN_NAV_ITEMS } from '../../../common/constants/navigation';
import { useLocation } from 'react-router-dom';
import { headerData } from '../../../common/components/Navigation/Navigation';
/**
 * Will render the section accessed by system administrator
 */
const Admin = () => {
    headerData.text = 'System administration';
    headerData.href = '#';
    const { initializeNavigation } = useGrimsbyNavActions();
    const location = useLocation();
    const initializeNavigationCb = useCallback(initializeNavigation, []);

    useEffect(() => {
        initializeNavigationCb(ADMIN_NAV_ITEMS, true);

        return () => {
            initializeNavigationCb([], false);
        };
    }, [location, initializeNavigationCb]);

    return <AdminRoutes routes={ADMIN_NAV_ITEMS} />;
};

export default Admin;
