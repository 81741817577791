import {
    Box,
    Button,
    Container,
    FormField,
    Header,
    Popover,
    Select,
    SelectProps,
    SpaceBetween,
    StatusIndicator,
    StatusIndicatorProps,
} from '@amzn/awsui-components-react-v3';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    DATE_PICKER_DISPLAY_FORMAT,
    DATE_PICKER_VALUE_FORMAT,
} from '../../../../../common/utils/cloudscapeHelpers';
import { ActivityGroupMode } from '../../../../interfaces/activityGroup';
import {
    activityGroupSlice,
    addDeliveryOptionWithUser,
} from '../../../../store/slices/activityGroupSlice';
import { ActivityStatus } from '../../Common/Common';
import { useActivityGroup } from '../hooks';
import { AciMessage } from '../validations';
import DeliveryOption from './DeliveryOption';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export const Delivery = ({
    name,
    mode,
}: {
    name: string;
    mode: ActivityGroupMode;
}) => {
    const activityGroup = useActivityGroup();
    const dispatch = useDispatch();
    const weekOptionsForDates = useMemo<SelectProps.Option[]>(() => {
        const startTime = dayjs
            .unix(activityGroup.start_timestamp)
            .tz(activityGroup.selected_timezone);
        const endTime = dayjs
            .unix(activityGroup.end_timestamp)
            .tz(activityGroup.selected_timezone);
        const numWeeks = endTime.diff(startTime, 'week') + 1;

        return [...new Array(numWeeks)].map((_, index) => {
            const weekOfString = startTime
                .add(index, 'week')
                .startOf('week')
                .format(DATE_PICKER_DISPLAY_FORMAT);

            return {
                value: weekOfString,
                label: `Week ${index + 1} (Starting: ${weekOfString})`,
            };
        });
    }, [activityGroup.start_timestamp, activityGroup.end_timestamp]);
    const [selectedWeek, setSelectedWeek] = useState(weekOptionsForDates[0]);
    const [applyAllStatusConfig, setApplyAllStatusConfig] = useState<{
        type: StatusIndicatorProps.Type;
        text: string;
    }>({
        type: 'success',
        text: 'Applied to all weeks',
    });

    const getActiveChildActivities = () =>
        activityGroup.child_activities.filter(
            (activity) => activity.activity_status !== 'Canceled',
        );
    const activeChildActivities = getActiveChildActivities();

    const isAddOptionDisabled = () => {
        return (
            activeChildActivities.length >= 5 ||
            ![ActivityStatus.Hold, ActivityStatus.Tentative].includes(
                activityGroup.status,
            )
        );
    };

    const getAddOptionDisableReason = () => {
        return activeChildActivities.length >= 5
            ? AciMessage.MAX_ACTIVITY_PER_LAG
            : 'Options can only be added with Hold/Tentative status';
    };

    const getDeliveryActivities = () =>
        activityGroup.child_activities
            .filter((activity) => activity.delivery_name === name)
            .sort((a, b) => a.option_number - b.option_number);

    const containsOneDelivery =
        Object.keys(activityGroup.delivery_map).length === 1;
    const isReadOnlyMode = mode === 'VIEW';

    const copyOptionsToAllWeeks = () => {
        const startOfWeekUtc = dayjs
            .tz(
                selectedWeek.value,
                DATE_PICKER_VALUE_FORMAT,
                activityGroup.selected_timezone,
            )
            .utc();
        const endOfWeekUtc = startOfWeekUtc.add(1, 'week');

        for (const activity of getDeliveryActivities()) {
            const sessionsToCopy = activity.delivery_sessions.filter(
                (session) => {
                    const sessionStart = dayjs.unix(session.start_timestamp);
                    const sessionEnd = dayjs.unix(session.end_timestamp);
                    return (
                        sessionStart.isAfter(startOfWeekUtc) &&
                        sessionEnd.isBefore(endOfWeekUtc)
                    );
                },
            );

            dispatch(
                activityGroupSlice.actions.clearDeliverySessions({
                    deliveryName: name,
                    optionNumber: activity.option_number,
                }),
            );

            const firstSessionDay = dayjs.unix(activityGroup.start_timestamp);
            const lastSessionDay = dayjs.unix(activityGroup.end_timestamp);

            for (const session of sessionsToCopy) {
                for (
                    let targetDay = firstSessionDay;
                    targetDay.isBefore(lastSessionDay);
                    targetDay = targetDay.add(1, 'week')
                ) {
                    const startOfWeek = targetDay.startOf('week');
                    const copiedSessionDay = dayjs.unix(
                        session.start_timestamp,
                    );
                    const copiedSessionDiffFromStart = copiedSessionDay.diff(
                        copiedSessionDay.startOf('week'),
                        'seconds',
                    );

                    const newSessionStart = startOfWeek.add(
                        copiedSessionDiffFromStart,
                        'seconds',
                    );
                    const newSessionEnd = newSessionStart.add(
                        session.end_timestamp - session.start_timestamp,
                        'seconds',
                    );

                    const { id, ...sessionSettings } = session;

                    if (
                        newSessionStart.unix() >=
                            activityGroup.start_timestamp &&
                        newSessionEnd.unix() <= activityGroup.end_timestamp
                    ) {
                        dispatch(
                            activityGroupSlice.actions.addDeliverySession({
                                deliveryName: name,
                                optionNumber: activity.option_number,
                                deliverySession: {
                                    ...sessionSettings,
                                    start_timestamp: newSessionStart.unix(),
                                    end_timestamp: newSessionEnd.unix(),
                                },
                            }),
                        );
                    }
                }
            }
        }
    };

    const handleApplyToAllWeeks = () => {
        try {
            copyOptionsToAllWeeks();
            setApplyAllStatusConfig({
                type: 'success',
                text: 'Applied to all weeks',
            });
        } catch {
            setApplyAllStatusConfig({
                type: 'error',
                text: 'Failed to apply to all weeks',
            });
        }
    };

    const deliveryHeader = (
        <Header
            actions={
                !isReadOnlyMode && (
                    <Popover
                        triggerType="custom"
                        dismissButton={true}
                        content={
                            <StatusIndicator type={applyAllStatusConfig.type}>
                                {applyAllStatusConfig.text}
                            </StatusIndicator>
                        }
                    >
                        <Button onClick={handleApplyToAllWeeks}>
                            Apply To All Weeks
                        </Button>
                    </Popover>
                )
            }
        >
            {name}
        </Header>
    );

    return (
        <Container header={deliveryHeader}>
            <SpaceBetween size="l" direction="vertical">
                <FormField stretch={true} label="Week">
                    <Select
                        options={weekOptionsForDates}
                        selectedOption={selectedWeek}
                        onChange={({ detail }) =>
                            setSelectedWeek(detail.selectedOption)
                        }
                    ></Select>
                </FormField>

                {getDeliveryActivities().map((deliveryActivity) => (
                    <DeliveryOption
                        key={
                            deliveryActivity.delivery_name +
                            deliveryActivity.option_number
                        }
                        deliveryName={deliveryActivity.delivery_name}
                        optionNumber={deliveryActivity.option_number}
                        weekStartDate={selectedWeek.value}
                        isReadOnlyMode={isReadOnlyMode}
                    ></DeliveryOption>
                ))}

                <Box>
                    {!isReadOnlyMode && (
                        <Button
                            disabled={isAddOptionDisabled()}
                            disabledReason={getAddOptionDisableReason()}
                            onClick={() =>
                                dispatch(addDeliveryOptionWithUser(name))
                            }
                            data-testid={`add-delivery-option-button-${name}`}
                        >
                            Add Option
                        </Button>
                    )}

                    {!containsOneDelivery && !isReadOnlyMode && (
                        <Box float="right">
                            <Button
                                onClick={() =>
                                    dispatch(
                                        activityGroupSlice.actions.removeDelivery(
                                            name,
                                        ),
                                    )
                                }
                                disabled={
                                    Object.keys(activityGroup.delivery_map)
                                        .length === 1
                                }
                            >
                                Remove Delivery
                            </Button>
                        </Box>
                    )}
                </Box>
            </SpaceBetween>
        </Container>
    );
};

export default Delivery;
