import {
    Box,
    Button,
    ColumnLayout,
    CopyToClipboard,
    FormField,
    Input,
    Popover,
    Select,
    SpaceBetween,
    TextContent,
    TokenGroup,
} from '@amzn/awsui-components-react-v3';
import dayjs from 'dayjs';
import { Fragment, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
    valuesToOptions,
    valueToOption,
} from '../../../../../common/utils/cloudscapeHelpers';
import { V_ILT_TYPE } from '../../../../constants';
import { DAYS_OF_THE_WEEK } from '../../../../constants/datetime';
import { DeliverySession } from '../../../../interfaces/activity';
import { activityGroupSlice } from '../../../../store/slices/activityGroupSlice';
import { useActivityGroup, useDeliverySessionTypes } from '../hooks';
import { AciMessage, AciResouceConstrain } from '../validations';

export interface SessionDetailsProps {
    sessions: DeliverySession[];
    deliveryName: string;
    optionNumber: number;
    optionStartTime: string;
    optionStartTimeMeridiem: string;
    optionEndTime: string;
    optionEndTimeMeridiem: string;
    defaultSessionStartTime: number;
    defaultSessionEndTime: number;
    isReadOnlyMode: boolean;
    selectedWeekStartDay: dayjs.Dayjs;
    selectedWeekEndDay: dayjs.Dayjs;
}

const MAX_DELIVERY_SESSION =
    AciResouceConstrain.MAX_DELIVERY_SESSION_PER_ACTIVITY;

const SessionDetails = (props: SessionDetailsProps) => {
    const {
        sessions,
        deliveryName,
        optionNumber,
        optionStartTime,
        optionStartTimeMeridiem,
        optionEndTime,
        optionEndTimeMeridiem,
        defaultSessionStartTime,
        defaultSessionEndTime,
        isReadOnlyMode,
    } = props;

    const dispatch = useDispatch();
    const activityGroup = useActivityGroup();
    const deliverySessionTypes = useDeliverySessionTypes();
    const [optionStartTimeHours, optionStartTimeMinutes] = optionStartTime
        .split(':')
        .map((val) => parseInt(val));
    const [optionEndTimeHours, optionEndTimeMinutes] = optionEndTime
        .split(':')
        .map((val) => parseInt(val));
    const startTimeMeridiemOffsetHours =
        optionStartTimeMeridiem === 'PM' && optionStartTimeHours < 12 ? 12 : 0;
    const endTimeMeridiemOffsetHours =
        optionEndTimeMeridiem === 'PM' && optionEndTimeHours < 12 ? 12 : 0;
    const activityGroupStartDay = dayjs.tz(
        dayjs.unix(activityGroup.start_timestamp),
        activityGroup.selected_timezone,
    );
    const activityGroupEndDay = dayjs.tz(
        dayjs.unix(activityGroup.end_timestamp),
        activityGroup.selected_timezone,
    );
    const instructorOptions = activityGroup.instructor_pool.map(
        (instructor) => ({
            value: JSON.stringify(instructor),
            label: `${instructor.name} (${instructor.email})`,
        }),
    );
    const addDeliverySession = () =>
        dispatch(
            activityGroupSlice.actions.addDeliverySession({
                deliveryName,
                optionNumber,
                deliverySession: {
                    start_timestamp: defaultSessionStartTime,
                    end_timestamp: defaultSessionEndTime,
                    delivery_session_type: deliverySessionTypes[0],
                    instructors: [],
                    v_ilt_info: {
                        type: V_ILT_TYPE.PVILT,
                    },
                },
            }),
        );
    const setDeliverySession = (deliverySession: DeliverySession) =>
        dispatch(
            activityGroupSlice.actions.setDeliverySession({
                deliveryName,
                optionNumber,
                deliverySession,
            }),
        );
    const removeDeliverySession = (id: string) =>
        dispatch(
            activityGroupSlice.actions.removeDeliverySession({
                deliveryName,
                optionNumber,
                id,
            }),
        );

    const weekDayOptions = useMemo(() => {
        if (props.selectedWeekStartDay.isBefore(activityGroupStartDay)) {
            return valuesToOptions(
                DAYS_OF_THE_WEEK.slice(
                    activityGroupStartDay.day(),
                    DAYS_OF_THE_WEEK.length + 1,
                ),
            );
        } else if (activityGroupEndDay.isBefore(props.selectedWeekEndDay)) {
            return valuesToOptions(
                DAYS_OF_THE_WEEK.slice(0, activityGroupEndDay.day() + 1),
            );
        }

        return valuesToOptions(DAYS_OF_THE_WEEK);
    }, [
        activityGroupStartDay,
        activityGroupEndDay,
        props.selectedWeekStartDay,
        props.selectedWeekEndDay,
    ]);

    const getMeetingLink = ({
        v_ilt_info: { type, streamyard_url, url },
    }: DeliverySession) =>
        type === V_ILT_TYPE.PVILT ? streamyard_url ?? '' : url ?? '';

    return (
        <SpaceBetween size="l">
            <ColumnLayout columns={6} minColumnWidth={1}>
                {sessions.map((session, index) => {
                    const isHeaderRow = index === 0;
                    const meetingLink = getMeetingLink(session);
                    const dayOfSession = session.start_timestamp
                        ? dayjs.tz(
                              dayjs.unix(session.start_timestamp),
                              activityGroup.selected_timezone,
                          )
                        : activityGroupStartDay;
                    const sessionInstructorOptions =
                        session.instructors?.map((instructor) => ({
                            label: `${instructor.name} (${instructor.email})`,
                            value: JSON.stringify(instructor),
                        })) || [];

                    return (
                        <Fragment key={session.id}>
                            <FormField label={isHeaderRow && 'Session Type'}>
                                <Select
                                    options={valuesToOptions(
                                        deliverySessionTypes,
                                    )}
                                    selectedOption={valueToOption(
                                        session.delivery_session_type ||
                                            deliverySessionTypes[0],
                                    )}
                                    disabled={isReadOnlyMode}
                                    onChange={({ detail }) =>
                                        setDeliverySession({
                                            ...session,
                                            delivery_session_type:
                                                detail.selectedOption.value,
                                        })
                                    }
                                    data-testid={`session-type-select-${deliveryName}-${optionNumber}-${index}`}
                                ></Select>
                            </FormField>

                            <FormField label={isHeaderRow && 'Session Day'}>
                                <Select
                                    options={weekDayOptions}
                                    selectedOption={valueToOption(
                                        DAYS_OF_THE_WEEK[dayOfSession.day()],
                                    )}
                                    disabled={isReadOnlyMode}
                                    onChange={({ detail }) => {
                                        const dayOfWeekIndex =
                                            DAYS_OF_THE_WEEK.indexOf(
                                                detail.selectedOption.value,
                                            );
                                        const sessionDay = dayjs.tz(
                                            dayjs.unix(session.start_timestamp),
                                            activityGroup.selected_timezone,
                                        );
                                        const startOfSessionWeek =
                                            sessionDay.startOf('week');
                                        const newSessionStart =
                                            startOfSessionWeek
                                                .add(dayOfWeekIndex, 'day')
                                                .hour(
                                                    optionStartTimeHours +
                                                        startTimeMeridiemOffsetHours,
                                                )
                                                .minute(optionStartTimeMinutes)
                                                .second(0);

                                        const newSessionEnd = startOfSessionWeek
                                            .add(dayOfWeekIndex, 'day')
                                            .hour(
                                                optionEndTimeHours +
                                                    endTimeMeridiemOffsetHours,
                                            )
                                            .minute(optionEndTimeMinutes)
                                            .second(0);

                                        setDeliverySession({
                                            ...session,
                                            start_timestamp:
                                                newSessionStart.unix(),
                                            end_timestamp: newSessionEnd.unix(),
                                        });
                                    }}
                                    data-testid={`session-day-select-${deliveryName}-${optionNumber}-${index}`}
                                ></Select>
                            </FormField>

                            <FormField
                                stretch={true}
                                label={isHeaderRow && 'Instructors'}
                            >
                                <SpaceBetween direction="horizontal" size="s">
                                    <Popover
                                        header="Add Instructor"
                                        content={
                                            <Select
                                                options={instructorOptions}
                                                selectedOption={null}
                                                placeholder="Select an Instructor"
                                                onChange={({ detail }) => {
                                                    const instructor =
                                                        JSON.parse(
                                                            detail
                                                                .selectedOption
                                                                .value,
                                                        );
                                                    dispatch(
                                                        activityGroupSlice.actions.addInstructorToDeliverySession(
                                                            {
                                                                deliveryName,
                                                                optionNumber,
                                                                instructor,
                                                                deliverySessionId:
                                                                    session.id,
                                                            },
                                                        ),
                                                    );
                                                }}
                                                empty="No Instructors in pool"
                                                data-testid={`add-instructor-select-${deliveryName}-${optionNumber}-${index}`}
                                            ></Select>
                                        }
                                        data-testid={`add-instructor-popover-${deliveryName}-${optionNumber}-${index}`}
                                    >
                                        <Button
                                            iconName="add-plus"
                                            disabled={isReadOnlyMode}
                                            data-testid={`add-instructor-button-${deliveryName}-${optionNumber}-${index}`}
                                        ></Button>
                                    </Popover>

                                    <Box float="right">
                                        <TokenGroup
                                            disableOuterPadding
                                            items={sessionInstructorOptions}
                                            readOnly={isReadOnlyMode}
                                            onDismiss={(e) => {
                                                const instructor = JSON.parse(
                                                    sessionInstructorOptions[
                                                        e.detail.itemIndex
                                                    ].value,
                                                );
                                                dispatch(
                                                    activityGroupSlice.actions.removeInstructorFromDeliverySession(
                                                        {
                                                            deliveryName,
                                                            optionNumber,
                                                            instructorPk:
                                                                instructor.pk,
                                                            deliverySessionId:
                                                                session.id,
                                                        },
                                                    ),
                                                );
                                            }}
                                            data-testid={`session-instructor-token-group-${deliveryName}-${optionNumber}-${index}`}
                                        ></TokenGroup>
                                    </Box>
                                </SpaceBetween>
                            </FormField>

                            <FormField label={isHeaderRow && 'Meeting Type'}>
                                <Select
                                    selectedOption={valueToOption(
                                        session.v_ilt_info.type,
                                    )}
                                    options={valuesToOptions(
                                        Object.values(V_ILT_TYPE),
                                    )}
                                    disabled={isReadOnlyMode}
                                    onChange={({ detail }) =>
                                        setDeliverySession({
                                            ...session,
                                            v_ilt_info: {
                                                ...session.v_ilt_info,
                                                type: detail.selectedOption
                                                    .value,
                                            },
                                        })
                                    }
                                    data-testid={`meeting-type-select-${deliveryName}-${optionNumber}-${index}`}
                                ></Select>
                            </FormField>

                            <FormField label={isHeaderRow && 'Meeting Link'}>
                                <SpaceBetween direction="horizontal" size="s">
                                    <Input
                                        type="url"
                                        value={meetingLink}
                                        disabled={
                                            isReadOnlyMode ||
                                            session.v_ilt_info.type !==
                                                V_ILT_TYPE.PVILT
                                        }
                                        onChange={(event) =>
                                            setDeliverySession({
                                                ...session,
                                                v_ilt_info: {
                                                    ...session.v_ilt_info,
                                                    streamyard_url:
                                                        event.detail.value,
                                                },
                                            })
                                        }
                                        data-testid={`streamyard-link-input-${deliveryName}-${optionNumber}-${index}`}
                                    ></Input>

                                    {meetingLink.length > 0 && (
                                        <CopyToClipboard
                                            copyButtonText="Copy Meeting URL"
                                            copyErrorText="Link failed to copy"
                                            copySuccessText="Meeting Link copied"
                                            textToCopy={meetingLink}
                                            variant="icon"
                                            data-testid={`copy-meeting-link-${deliveryName}-${optionNumber}-${index}`}
                                        />
                                    )}
                                </SpaceBetween>
                            </FormField>

                            <Box>
                                <Box
                                    display="inline-block"
                                    float="right"
                                    padding={{ left: 's' }}
                                >
                                    {isHeaderRow && (
                                        <TextContent>Delete</TextContent>
                                    )}
                                    <Button
                                        iconName="remove"
                                        disabled={isReadOnlyMode}
                                        onClick={() =>
                                            removeDeliverySession(session.id)
                                        }
                                        data-testid={`delete-session-button-${deliveryName}-${optionNumber}-${index}`}
                                    ></Button>
                                </Box>
                            </Box>
                        </Fragment>
                    );
                })}
            </ColumnLayout>

            {!isReadOnlyMode && (
                <Button
                    onClick={() => addDeliverySession()}
                    data-testid={`add-session-button-${deliveryName}-${optionNumber}`}
                    disabled={sessions.length >= MAX_DELIVERY_SESSION}
                    disabledReason={
                        AciMessage.MAX_DELIVERY_SESSION_PER_ACTIVITY
                    }
                >
                    Add Session
                </Button>
            )}
        </SpaceBetween>
    );
};

export default SessionDetails;
