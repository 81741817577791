import React from 'react';
import { FormField, DatePicker } from '@amzn/awsui-components-react-v3';
import {
    isDateEnabled,
    useBilledDatePicker,
    validateBilledDate,
} from './hooks/useBilledDatePicker';

interface BilledDatePickerProps {
    errors: { billed_date?: string };
    formValues: {
        billed_date?: number;
    };
    handleFieldEvent: (values: any) => void;
}

const BilledDatePicker = ({
    errors,
    formValues,
    handleFieldEvent,
}: BilledDatePickerProps) => {
    const { datePickerValue, handleDateChange } = useBilledDatePicker({
        formValues,
        handleFieldEvent,
    });

    return (
        <FormField errorText={errors?.billed_date} label="Billed Date">
            <DatePicker
                invalid={!!errors?.billed_date}
                onChange={({ detail }) => handleDateChange(detail)}
                isDateEnabled={isDateEnabled}
                value={datePickerValue}
                openCalendarAriaLabel={(selectedDate) =>
                    'Choose billed date' +
                    (selectedDate ? `, selected date is ${selectedDate}` : '')
                }
                placeholder="YYYY/MM-DD"
                dateDisabledReason={(date) => {
                    const result = validateBilledDate(datePickerValue);
                    return result === false ? '' : result;
                }}
            />
        </FormField>
    );
};

export default BilledDatePicker;
export { validateBilledDate };
