import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

interface UseBilledDatePickerProps {
    formValues: {
        billed_date?: number;
    };
    handleFieldEvent: (values: any) => void;
}

export const isDateEnabled = (date: Date) => {
    const dateObj = dayjs.isDayjs(date)
        ? date
        : typeof date === 'number'
          ? dayjs.unix(date)
          : dayjs(date);
    const nowUTC = dayjs().utc();
    const endOfTodayUTC = nowUTC.endOf('day');
    const selectedDate = dateObj.utc();
    const isTodayValid =
        selectedDate.isSame(endOfTodayUTC, 'day') &&
        nowUTC.isBefore(endOfTodayUTC.add(1, 'day'));
    const isFutureDate = selectedDate.isAfter(endOfTodayUTC);
    const isCurrentMonth = selectedDate.month() === nowUTC.month();

    return (isTodayValid || isFutureDate) && isCurrentMonth;
};

export const validateBilledDate = (date: number | Date | string) => {
    const dateObj = dayjs.isDayjs(date)
        ? date
        : typeof date === 'number'
          ? dayjs.unix(date)
          : dayjs(date);

    const nowUTC = dayjs().utc();
    const startOfTodayUTC = nowUTC.startOf('day');
    const endOfTodayUTC = nowUTC.endOf('day');
    const selectedDate = dateObj.utc();

    if (!dateObj.isValid()) {
        return 'Invalid date.';
    }

    const isAfterToday = selectedDate.isAfter(startOfTodayUTC);
    const isBeforeOrEqualEndOfDayUTC =
        selectedDate.isBefore(endOfTodayUTC) ||
        selectedDate.isAfter(startOfTodayUTC);
    const isCurrentMonth = selectedDate.month() === nowUTC.month();

    if (!isAfterToday) {
        return 'Select a future date.';
    }

    if (!isCurrentMonth) {
        return 'Date must be in the current month.';
    }

    if (!isBeforeOrEqualEndOfDayUTC) {
        return 'Date must be within today UTC';
    }

    return false;
};

export const useBilledDatePicker = ({
    formValues,
    handleFieldEvent,
}: UseBilledDatePickerProps) => {
    const [datePickerValue, setDatePickerValue] = useState(() => {
        return formValues.billed_date
            ? dayjs.unix(formValues.billed_date).format('YYYY-MM-DD')
            : dayjs().endOf('month').format('YYYY-MM-DD');
    });

    useEffect(() => {
        if (!formValues.billed_date) {
            const defaultDate = dayjs().endOf('month').format('YYYY-MM-DD');
            handleFieldEvent({
                billed_date: dayjs(defaultDate).unix(),
            });
        }
    }, []);

    useEffect(() => {
        const newDateValue = formValues.billed_date
            ? dayjs.unix(formValues.billed_date).format('YYYY-MM-DD')
            : dayjs().endOf('month').format('YYYY-MM-DD');
        setDatePickerValue(newDateValue);
    }, [formValues.billed_date]);

    const handleDateChange = (detail: { value: string | null }) => {
        const newValue = detail.value || '';
        setDatePickerValue(newValue);
        const isValidDate = dayjs(newValue, 'YYYY-MM-DD', true).isValid();

        if (isValidDate) {
            handleFieldEvent({
                billed_date: dayjs(newValue).unix(),
            });
        }
    };

    return {
        datePickerValue,
        handleDateChange,
    };
};
